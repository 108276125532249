import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import Static from 'ol/source/ImageStatic';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import { defaults as defaultInteractions } from 'ol/interaction.js';
import { toLonLat } from 'ol/proj';
import View from 'ol/View';
import { Icon, Style } from 'ol/style';
import { Image as ImageLayer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';


window.vectorSource = new VectorSource({

});

// vectorSource.addFeatures([iconFeature]);

const vectorLayer = new VectorLayer({
    source: vectorSource,
});

const layer = new TileLayer({
    source: new OSM(),
});


/**
 * Elements that make up the popup.
 */
const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');

/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
    element: container,
    autoPan: true,
    autoPanAnimation: {
        duration: 250,
    },
});

const imageLayer = new ImageLayer();

/**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};

window.map = new Map({
    interactions: defaultInteractions({ mouseWheelZoom: false }),
    layers: [imageLayer, vectorLayer],
    overlays: [overlay],
    target: document.getElementById('map'),
    view: new View({
        projection: 'EPSG:4326',
        center: [120.2845572, 22.7333814],
        extent: [120.27490352256049, 22.729900138577136, 120.29095524869918, 22.73823016457834],
        zoom: 16,
    }),
});

const source = new Static({
    url:
        baseUrl +
        '/images/data/map.png',
    crossOrigin: '',
    projection: 'EPSG:4326',
    imageExtent: [120.27490352256049, 22.729900138577136, 120.29095524869918, 22.73823016457834],
    imageSmoothing: false,
});
imageLayer.setSource(source);

const element = document.getElementById('popup');

const popup = new Overlay({
    element: element,
    positioning: 'bottom-center',
    stopEvent: false,
});
map.addOverlay(popup);

// display popup on click
map.on('click', function (evt) {
    const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
    });
    $(element).popover('dispose');
    if (feature) {
        popup.setPosition(evt.coordinate);
        $(element).popover({
            placement: 'top',
            html: true,
            content: feature.get('name'),
        });
        $(element).popover('show');

        setTimeout(function () {
            if (window.showTheme) {
                window.showTheme();
            }
        }, 500);
    } else {
        $(element).popover('dispose');
    }
    console.log(toLonLat(evt.coordinate, "EPSG:4326"))
});

// change mouse cursor when over marker
map.on('pointermove', function (e) {
    const pixel = map.getEventPixel(e.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel);
    map.getTarget().style.cursor = hit ? 'pointer' : '';
});

// Close the popup when the map is moved
map.on('movestart', function () {
    $(element).popover('dispose');
});

window.addFeatures = function (fArr) {
    vectorSource.clear();

    $.each(fArr, function () {
        var me = this;
        me.hasTheme = false; // 是否有成果

        var html = '';
        if (me.Url && me.Url.length > 0) {
            html = '<a href="' + me.Url + '" target="_blank">' + me.Name + '</a>';
        } else {
            html = me.Name;
        }
        if (me.UsedFiles && me.UsedFiles.length > 0) {
            me.hasTheme = true;

            $.each(me.UsedFiles, function () {
                html += '<div class="pop-img"><img src="' + basePath + this.ServerPath + '"></div>';
            });
        }

        if (me.customHtml) {
            html += me.customHtml;
        }

        if (me.ThemeSubs && me.ThemeSubs.length > 0) {
            html += '<div class="col-12"><p class="mp-sub-title">主題成果</p><ul>';
            $.each(me.ThemeSubs, function () {
                html += '<li><a href="' + baseUrl + '/main/theme_detail/' + this.Id + '2">' + this.Name + '</a></li>';
            });
            html += '</ul></div>';
        }

        const iconFeature = new Feature({
            geometry: new Point(toLonLat([parseFloat(me.Longitude), parseFloat(me.Latitude)], 'EPSG:4326', 'EPSG:900913')),
            name: html,
            population: 4000,
            rainfall: 500,
        });

        var markerPng = '/images/data/marker-a.png';
        if (me.hasTheme) {
            markerPng = '/images/data/marker.png';
        }
        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: baseUrl + markerPng,
            }),
        });

        iconFeature.setStyle(iconStyle);

        vectorSource.addFeature(iconFeature);
    });

}